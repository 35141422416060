import React, { useCallback } from 'react'

import { TableRow, TableHeader, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Grid } from '@mui/material'

import Header from './Header'
import Row from './Row'
import Form from './Form'
import Callbacks from './callbacks'
import { hooks } from '@front/volcanion'

const VehicleHistoryList = (props) => {
  const { vehicle_id } = hooks.useParams()
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(vehicle_id), [vehicle_id])
  return <>
    <Grid item xs={12}>
      <SearchFrame model_name={'action'} populate={['rubric', 'group', 'operator.info']} allowCreate={false} >
        <SearchForm
          submitOnMount
          disableButton
          filterToForm={filterToForm}
          formToFilter={formToFilter}
        >
          <FormContent><Form /></FormContent>
        </SearchForm>
        <SearchTable
          tableProps={{
            enableRowSelection: false
          }}
        >
          <TableHeader><Header /></TableHeader>
          <TableRow>
            <Row />
          </TableRow>
        </SearchTable>
      </SearchFrame>
    </Grid>
  </>
}


export default React.memo(VehicleHistoryList)