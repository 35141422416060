import React, { useCallback, useMemo } from 'react'
import { hooks } from '@front/volcanion'

import FormatUtils from '@front/squirtle/utils/format'

import Callbacks from './callbacks'
import Aux from '../../Aux'


const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [isReadOnly] = hooks.useFormState('isReadOnly')
  const [supported_languages] = hooks.useSetting('supported_languages')
  const [status] = hooks.useFieldValues(['status'])
  const [first_name, last_name, designation] = hooks.useFieldValues(['info.first_name', 'info.last_name', 'customerinfo.name'])

  const [, { openDialog }] = hooks.useDialogs()
  const suspendedStateName = 'suspended'
  const [, setSuspendedState] = hooks.useFormState(suspendedStateName)
  const [user] = hooks.useModel('user', [record_id], { single: true, populate: ['auth'] })
  const [modifyUser] = hooks.useModelFunction('user', 'modify')

  const formattedSuspension = Aux.parseStatus(user)
  const statusName = FormatUtils.getStatus(_.pick(_.get(user, 'auth'), ['active', 'deleted', 'suspendedUntil', 'suspendedSince']))

  const initialSuspendedValues = {
    name: statusName,
    motive: user?.auth?.motive,
    suspendedSince: user?.auth?.suspendedSince,
    suspendedUntil: user?.auth.suspendedUntil
  }

  const statusChange = useCallback(Callbacks.statusChangeHandler(openDialog, setSuspendedState), [openDialog, setSuspendedState])
  const onClickRemoveSuspend = useCallback(Callbacks.onClickRemoveSuspendHandler(openDialog), [openDialog])
  const onConfirmRemoveSuspend = useCallback(Callbacks.onConfirmRemoveSuspendHandler(record_id, modifyUser), [record_id, modifyUser])
  const handleClientNumberChange = useCallback(hooks.useRecordExist('user', 'customerinfo.client_number'), [])

  const color = useMemo(() => {
    if (!!_.includes(['inactive', 'archived', 'suspended'], status)) return 'orange'
    else if (status === 'active') return 'green'
    else return 'red'
  }, [status])

  const mergedProps = {
    handleClientNumberChange,
    namesRequired: !designation,
    designationRequired: !first_name && !last_name,
    status,
    supported_languages,
    isManagingRecord,
    formattedSuspension,
    color,
    statusChange,
    suspendedStateName,
    initialSuspendedValues,
    onClickRemoveSuspend,
    onConfirmRemoveSuspend,
    isReadOnly,
  }

  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
