import React from 'react'
import { I18n } from '@front/volcanion'

import { ConfirmationDialog } from '@front/squirtle'

const Dialogs = ({ resetPassword, sendWelcomeEmail }) => (<>
  <ConfirmationDialog name={'confirm_reset_password'} onConfirm={resetPassword} />
  <ConfirmationDialog name={'confirm_welcome_email'} onConfirm={sendWelcomeEmail} />
</>
)

export default React.memo(Dialogs)
