import FormatUtils from '@front/squirtle/utils/format'
import { I18n } from '@front/volcanion'

class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        status: FormatUtils.getStatus(_.pick(_.get(record, 'auth'), ['active', 'deleted', 'suspendedUntil', 'suspendedSince'])),
        auth: {
          gsm: _.get(record, 'auth.gsm'),
          email: _.get(record, 'auth.email'),
          gsm_verified: _.get(record, 'auth.gsm_verified'),
          application: _.get(record, 'auth.application')
        },
        customerinfo: {
          client_number: _.get(record, 'customerinfo.client_number'),
          name: _.get(record, 'customerinfo.name'),
          email: _.get(record, 'customerinfo.email'),
          special_price: _.get(record, 'customerinfo.special_price'),
          auth_account: _.get(record, 'customerinfo.auth_account'),
          fee_rate: _.get(record, 'customerinfo.fee_rate'),
          fixed_fee: _.get(record, 'customerinfo.fixed_fee'),
          comment_to_driver: _.get(record, 'customerinfo.comment_to_driver'),
          comment_to_call_taker: _.get(record, 'customerinfo.comment_to_call_taker'),
          last_order_date: _.get(record, 'customerinfo.last_order_date'),
          first_order_date: _.get(record, 'customerinfo.first_order_date'),
          ivr_access: _.get(record, 'customerinfo.ivr_access'),
          driver_options: _.map(_.get(record, 'customerinfo.driver_options'), opt => _.get(opt, 'user_option_id', opt)),
          vehicle_options: _.map(_.get(record, 'customerinfo.vehicle_options'), opt => _.get(opt, 'vehicle_option_id', opt)),
          customer_billed_id: _.get(record, 'customerinfo.customer_billed_id.user_id')
        },
        info: {
          first_name: _.get(record, 'info.first_name'),
          last_name: _.get(record, 'info.last_name'),
          address: _.get(record, 'info.address'),
          preferred_language: _.get(record, 'info.preferred_language')
        },
        preference: {
          waiting_duration: record?.preference?.waiting_duration,
          preferred_music: record?.preference?.preferred_music,
          open_door: record?.preference?.open_door,
          air_conditioning: record?.preference?.air_conditioning,
          temperature: record?.preference?.temperature,
          open_window: record?.preference?.open_window,
          armrest: record?.preference?.armrest,
        },
        address: _.get(record, 'payment_methods'),
        payment_methods: _.get(record, 'payment_methods'),
        deferred_billing: _.get(record, 'deferred_billing'),
        commercial_formula: _.get(record, 'commercial_formula'),
        createdAt: _.get(record, 'createdAt'),
        exclude_heatmap: record?.exclude_heatmap
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyFormValues() {
      return {
        status: 'active',
        customerinfo: {
          vehicle_options: [],
          driver_options: []
        }
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta, { suspended, ...state }) {
      return {
        status: FormatUtils.parseStatus(_.get(values, 'status'), _.pick(suspended, ['suspendedUntil', 'suspendedSince', 'motive'])),
        auth: {
          gsm: _.get(values, 'auth.gsm'),
          email: _.get(values, 'auth.email'),
          gsm_verified: _.get(values, 'auth.gsm_verified'),
          application: _.get(values, 'auth.application')
        },
        customerinfo: {
          client_number: _.get(values, 'customerinfo.client_number'),
          name: _.get(values, 'customerinfo.name'),
          email: _.get(values, 'customerinfo.email'),
          special_price: _.get(values, 'customerinfo.special_price'),
          auth_account: _.get(values, 'customerinfo.auth_account'),
          fee_rate: _.get(values, 'customerinfo.fee_rate'),
          fixed_fee: _.get(values, 'customerinfo.fixed_fee'),
          comment_to_driver: _.get(values, 'customerinfo.comment_to_driver'),
          comment_to_call_taker: _.get(values, 'customerinfo.comment_to_call_taker'),
          ivr_access: _.get(values, 'customerinfo.ivr_access'),
          driver_options: _.get(values, 'customerinfo.driver_options'),
          vehicle_options: _.get(values, 'customerinfo.vehicle_options'),
          customer_billed_id: _.get(values, 'customerinfo.customer_billed_id')
        },
        info: {
          first_name: _.get(values, 'info.first_name'),
          last_name: _.get(values, 'info.last_name'),
          address: _.get(values, 'info.address'),
          preferred_language: _.get(values, 'info.preferred_language')
        },
        preference: {
          waiting_duration: values?.preference?.waiting_duration,
          preferred_music: values?.preference?.preferred_music,
          open_door: values?.preference?.open_door,
          air_conditioning: values?.preference?.air_conditioning,
          temperature: values?.preference?.temperature,
          open_window: values?.preference?.open_window,
          armrest: values?.preference?.armrest,
        },
        commercial_formula: _.get(values, 'commercial_formula'),
        address: _.get(values, 'payment_methods'),
        payment_methods: _.get(values, 'payment_methods'),
        deferred_billing: _.get(values, 'deferred_billing'),
        type: 'customer',
        exclude_heatmap: values?.exclude_heatmap
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        record_id: _.get(record, 'user_id'),
        title: _.get(record, 'customerinfo.client_number'),
        preferred_language: record?.info?.preferred_language || 'fr',
        suspended: {
          suspendedUntil: record?.auth?.suspendedUntil,
          suspendedSince: record?.auth?.suspendedSince,
          motive: record?.auth?.motive
        }
      }
    }
  }

  static onSubmitSuccessHandler(handleWelcomeAction) {
    return async function onSubmitSuccess(result, values, extra, meta) {
      if (!!values?.welcome_email)
        await handleWelcomeAction({ user_id: result?.user_id, channel: 'customer', adapter: 'email' })
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)

    }
  }
  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)
    }
  }
  static getFailedNotificationHandler() {
    return function getFailedNotification(err, data, extra, meta, state) {
      const code = err.getCode()
      const context = err.getContext()
      if (code === 'RECORD_EXISTS' && _.get(context, 'key') === 'client_number') {
        return [I18n.t('error.already_exists.client_number'), { variant: 'error' }]
      }
      else if (code === 'RECORD_EXISTS' && _.includes(context?.key, 'gsm')) {
        return [I18n.t('error.already_exists.gsm'), { variant: 'error' }]
      }
      else if (code === 'RECORD_EXISTS' && _.includes(context?.key, 'email')) {
        return [I18n.t('error.already_exists.email'), { variant: 'error' }]
      }
    }
  }
  static confirmResetHandler(openDialog, client_number, user_id) {
    return async function confirmReset(method) {
      openDialog('confirm_reset_password', {
        title: I18n.t('password.reset.confirm.title'),
        description: I18n.t('password.reset.confirm.customer', { client_number, method }),
      }, { method, user_id }, { method, user_id }
      )
    }
  }
  static onResetSmsPasswordHandler(confirmReset) {
    return async function onResetSmsPassword() {
      confirmReset('sms')
    }
  }
  static onResetEmailPasswordHandler(confirmReset) {
    return async function onResetEmailPassword() {
      confirmReset('email')
    }
  }

  static confirmWelcomeEmailHandler(openDialog, client_number, user_id) {
    return async function confirmReset() {
      openDialog('confirm_welcome_email', {
        title: I18n.t('email.welcome.confirm.title'),
        description: I18n.t('email.welcome.confirm.customer', { client_number }),
      }, { user_id }, { user_id }
      )
    }
  }

  static handleCallHandler(EXTERNAL_PHONE_SERVICE) {
    return async function call(number) {
      if (!number) return
      if (EXTERNAL_PHONE_SERVICE === 'aws') {
        const endpoint = connect.Endpoint.byPhoneNumber(number)
        const agent = new connect.Agent()
        const contacts = agent.getContacts()
        if (_.isEmpty(contacts))
          agent.connect(endpoint)
        else if (contacts.length === 1)
          contacts[0].addConnection(endpoint)
      }
    }
  }

  static onKeyDownHandler(gsm, handleCall) {
    return async function onKeyDownHandler(event) {
      const { key, altKey, ctrlKey } = event

      if (!!altKey || !!ctrlKey) return

      switch (key) {
        case 'F9':
          event.preventDefault()
          await handleCall(gsm)
          break;
        default:
      }
    }
  }
}

export default Callbacks
